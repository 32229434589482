<template>
  <div class="instant__search">
    <TransitionExpand>
      <form action="/search" method="get" role="search" ref="form">
        <div class="instant__search__input__container">
          <input
            v-model="instantSearch.query"
            @keyup="search($event)"
            ref="input"
            :placeholder="
              props.placeholder
                ? props.placeholder
                : 'Search, eg. Rake, Seeds, 1880092'
            "
            class="instant-search__input"
            type="search"
            name="q"
            id="InstantSearch"
            autocomplete="off"
            maximum-scale="1"
          />
          <button ref="submit" type="submit">
            <icon-search />
          </button>
        </div>
        <!-- <input type="submit" /> -->
        <div
          v-if="
            search_active &&
            instantSearch.all_empty !== true &&
            source === 'header'
          "
          class="instant__search__wrapper"
        >
          <div class="instant__search__container">
            <div class="instant__search__results">
              <div class="instant__search__results__arrow__wrap desktop:hidden"></div>
              <div class="instant__search__results__details">
                <section
                  v-if="instantSearch.suggestions.length"
                  class="instant__search__results__details--suggestions"
                >
                  <div class="instant__search__results__header">
                    <p class="small light">Popular Suggestions</p>
                  </div>
                  <ul>
                    <li
                      v-for="(suggestion, idx) in instantSearch.suggestions"
                      :key="idx"
                      @click.prevent="searchSuggestion(suggestion)"
                    >
                      <p class="small">{{ suggestion }}</p>
                    </li>
                  </ul>
                </section>
                <section
                  v-if="instantSearch.collections.length"
                  class="instant__search__results__details--categories"
                >
                  <div class="instant__search__results__header">
                    <p class="small light">Shop by category</p>
                  </div>
                  <ul>
                    <li
                      v-for="(collection, idx) in instantSearch.collections"
                      :key="idx"
                      @click="searchCollection(collection)"
                    >
                      <p class="small">{{ collection.title }}</p>
                    </li>
                  </ul>
                </section>
                <section
                  v-if="instantSearch.pages.length"
                  class="instant__search__results__details--categories"
                >
                  <div class="instant__search__results__header">
                    <p class="small light">Page results</p>
                  </div>
                  <ul>
                    <li v-for="(page, idx) in instantSearch.pages" :key="idx">
                      <a :href="page.url">
                        <p class="small">{{ page.title }}</p></a
                      >
                    </li>
                  </ul>
                </section>
                <section
                  class="instant__search__results__details--all-results hidden desktop:block"
                >
                  <div v-if="instantSearch.products_found">
                    <button
                      @click="submitForm"
                      type="submit"
                      class="instant__search__results__all__results"
                    >
                      <p class="small">
                        View All {{ instantSearch.products_found }} Products
                      </p>
                    </button>
                    <icon-arrow-right />
                  </div>
                  <div v-else>
                    <p class="small">No products found</p>
                  </div>
                </section>
              </div>
              <div
                v-if="instantSearch.products.length"
                class="instant__search__results__products"
              >
                <section class="instant__search__results__products--products">
                  <div class="instant__search__results__header">
                    <p class="small light">Products</p>
                  </div>
                  <ul>
                    <li
                      v-for="(product, idx) in instantSearch.products"
                      :key="idx"
                    >
                      <a
                        class="instant__search__results__product__item"
                        :href="`/products/${product.handle}`"
                      >
                        <!-- Image -->
                        <div class="product__item__image">
                          <VImg :media="image(product.images)" />
                        </div>
                        <div class="product__item__details">
                          <!-- Title -->
                          <p class="product__item__details__title small">
                            {{ product.title }}
                          </p>
                          <!-- Price -->
                          <p
                            v-if="props.customer.logged_in && price(product)"
                            class="product__item__details__price"
                          >
                            ${{ price(product) }}
                            <span>(plus GST)</span>
                          </p>
                        </div>
                      </a>
                    </li>
                  </ul>
                </section>
              </div>
              <div
                v-show="instantSearch.products_found"
                class="instant__search__results__all-results block desktop:hidden"
              >
                <div>
                  <button
                    type="submit"
                    class="instant__search__results__all__products"
                  >
                    <p class="small">
                      View All {{ instantSearch.products_found }} Products
                    </p>
                  </button>
                  <icon-arrow-right />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </TransitionExpand>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref, nextTick, computed } from 'vue';
import VImg from './VImg.vue';
import TransitionExpand from './transitions/TransitionExpand.vue';
import debounce from 'lodash/debounce';
import IconSearch from './icons/IconSearch.vue';
import IconArrowRight from './icons/IconArrowRight.vue';

const props = defineProps({
  terms: {
    type: String,
    required: false,
  },
  search_active: {
    type: Boolean,
    required: true,
    default: () => false,
  },
  customer: {
    type: Object,
    required: true,
  },
  placeholder: {
    type: String,
    required: false,
  },
  source: {
    type: String,
    required: false,
  },
});

const emit = defineEmits(['searchData', 'searchQuery']);

const form = ref(null);
const input = ref(null);
const submit = ref(null);

const settings = PS.Settings;

const instantSearch = reactive({
  query: '',
  suggestions: [],
  pages: [],
  products: [],
  collections: [],
  products_found: 0,
  all_empty: true,
});

// const headerResults = computed(() => {
//   return instantSearch.products.length || instantSearch.collections.length || instantSearch.suggestions.length ? true : false
// });

const activeFilter = (filter) => {};

const image = (image) => {
  let mediaObj =
    Object.keys(image).length !== 0
      ? {
          alt: null,
          height: 2000,
          id: null,
          position: 1,
          src: image['1'],
          width: 2000,
        }
      : {
          alt: null,
          height: 200,
          id: null,
          position: 1,
          src: `https:${settings.product_fallback_image}`,
          width: 200,
        };
  return mediaObj;
};

const search = debounce((e) => {
  instantSearch.products = [];

  // Escape
  if (e.key === 'Escape' || e.keyCode === 27) {
    instantSearch.query = '';
    instantSearch.all_empty = true;
    input.value.blur();
    return;
  }

  // Reset
  if (instantSearch.query.length < 3) {
    instantSearch.all_empty = true;
    return;
  }

  // BCSF Endpoint
  let url = new URL(
    'https://services.mybcapps.com/bc-sf-filter/search/suggest'
  );

  // Request options
  let params = {
    shop: 'amalgamated-hardware-merchants.myshopify.com',
    q: instantSearch.query,
    product_limit: 6,
  };

  // Create query string from params object
  Object.keys(params).forEach((key) => {
    url.searchParams.append(key, params[key]);
  });

  // Send request
  fetch(url)
    .then((response) => response.json())
    .then((data) => {
      if (props.source === 'search') {
        emit('searchData', data);
        emit('searchQuery', instantSearch.query);
      } else {
        instantSearch.all_empty = data.all_empty;
        instantSearch.suggestions = data.suggestions;
        instantSearch.pages = data.pages;
        instantSearch.collections = data.collections;
        instantSearch.products = data.products;
        instantSearch.products_found = data.total_product;
      }
    })
    .catch((error) => {
      console.log('error', error);
    });
}, 100);

const urlParamSearch = () => {
  // BCSF Endpoint
  let url = new URL(
    'https://services.mybcapps.com/bc-sf-filter/search/suggest'
  );

  // Request options
  let params = {
    shop: 'amalgamated-hardware-merchants.myshopify.com',
    q: props.terms,
    product_limit: 6,
  };

  // Create query string from params object
  Object.keys(params).forEach((key) => {
    url.searchParams.append(key, params[key]);
  });

  // Send request
  fetch(url)
    .then((response) => response.json())
    .then((data) => {
      emit('searchData', data);
      emit('searchQuery', instantSearch.query);
    })
    .catch((error) => {
      console.log('error', error);
    });
};

const price = (product) => {
  return product.variants[0].price
};

const searchSuggestion = (suggestion) => {
  instantSearch.query = suggestion;
  input.value = suggestion;
  nextTick(() => {
    form.value.submit();
  });
};

const searchCollection = (collection) => {
  instantSearch.query = '';
  input.value = null;
  window.location.href = '/collections/' + collection.handle;
};

const submitForm = () => {
  form.value.submit();
};

onMounted(() => {
  if (props.source === 'search' && props.terms) {
    urlParamSearch();
  }
});
</script>
