<template>
  <div>
    <svg
      width="15"
      height="10"
      viewBox="0 0 15 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7142 5.70203L10.9624 9.69579C10.6107 10.1014 9.99511 10.1014 9.64338 9.69579C9.26233 9.32137 9.26233 8.66615 9.64338 8.29173L11.7831 5.98284H0.937958C0.410357 5.98284 0 5.54602 0 4.9844C0 4.39158 0.410357 3.98596 0.937958 3.98596H11.7831L9.64338 1.70827C9.26233 1.33385 9.26233 0.678627 9.64338 0.304212C9.99511 -0.101404 10.6107 -0.101404 10.9624 0.304212L14.7142 4.29797C15.0953 4.67239 15.0953 5.32761 14.7142 5.70203Z"
        fill="#303030"
      />
    </svg>
  </div>
</template>
